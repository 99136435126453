import React, {useEffect} from "react"
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer"
import ImageAndText from "../components/ImageAndText"
import Hero from "../components/Hero"
import ValuePropositionTable from '../components/ValuePropositionTable'
import ContactForm from '../components/ContactForm'
import HouseBagniLogo from '../assets/logo/logo-bagni-white.svg'
import ToDoList from "../components/TodoList";
import ImagesWall from "../components/ImagesWall";

import catalogoArredi from "../assets/catalogo_arredi.pdf"
import catalogoCeramiche from "../assets/catalogo_ceramiche.pdf"

const Bagni = (props) => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="page bagni">
            <div className="container">
                <Hero 
                    img={'https://images.unsplash.com/photo-1532926381893-7542290edf1d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Il BAGNO SU MISURA PER TE'
                    logo={HouseBagniLogo}
                />
                <div className="inner-page">
                    <ImageAndText 
                        img={'https://images.unsplash.com/photo-1531125227120-bac862d2aeb9?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                        imgAlt='alt'
                        invert
                        title='Hai voglia di ristrutturare?'
                        text={'Trova la soluzione migliore per uno degli interventi più importanti della casa: la ristrutturazione bagno. Con un progetto chiavi in mano che vi accompagna dall’ideazione alla realizzazione della ristrutturazione bagno, ogni cliente potrà contare su un servizio efficiente e di alta qualità.'}
                    />
                </div>
                <div className="inner-page">
                    <ToDoList title="I nostri servizi" 
                        activities={[
                            "Trasformazione da vasca in doccia",
                            "Trasformazione da vasca in vasca",
                            "Trasformazione da doccia in doccia",
                            "Ristrutturazione completa o parziale del bagno",
                            "Rinnovo piastrelle moderne",
                            "Rifacimento impianto idrico-sanitario",
                            "Piatto doccia marmoresina",
                            "Box doccia scorrevoli",
                            "Box doccia walk in",
                            "Sanitari sospesi o filo muro",
                            "Mobili arredo bagno",
                            "Termoarredo",
                            "Rubinetterie"
                          ]} 
                    />
                </div>
                <div className="inner-page black">
                    <ValuePropositionTable 
                        title={'Vuoi rinnovare il tuo vecchio bagno?'}
                        text="Se rinnovi il bagno con House Bagni ottieni:"
                        icons={[
                            {
                                title: '', 
                                text: "50% di Sconto grazie alle detrazioni fiscali del Bonus Ristrutturazione                            ", 
                                icon: 'real_estate_agent'
                            },
                            {
                                title: '', 
                                text: 'Progetto 3D gratuito, vedi in anteprima il tuo nuovo bagno', 
                                icon: 'view_in_ar'
                            },
                            {
                                title: '', 
                                text: 'Consegna e installazione inclusa', 
                                icon: 'engineering'
                            },
                            {
                                title: '', 
                                text: 'Garanzia inclusa di 2 Anni', 
                                icon: 'heart_check'
                            },
                        ]}
                    />
                </div>
                <div className="inner-page">
                    <ImagesWall
                        title='Lasciati ispirare'
                        images={[]}
                    />
                </div>
                <Hero 
                    img={'https://images.unsplash.com/photo-1482731215275-a1f151646268?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Vuoi un nostro intervento?'
                    ctaText='Richiedi un preventivo'
                    ctaLink='#contact'
                />
                <Hero 
                    img={'https://plus.unsplash.com/premium_photo-1681487218607-b972081533e0?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Catalogo degli arredi'
                    ctaText='SCARICA IL PDF'
                    ctaLink={catalogoArredi}
                    ctaOpenNewPage
                />
                 <Hero 
                    img={'https://images.unsplash.com/photo-1471880504582-cf7e63045303?q=80&w=2604&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
                    imgAlt='alt'
                    title='Catalogo delle ceramiche'
                    ctaText='SCARICA IL PDF'
                    ctaLink={catalogoCeramiche}
                    ctaOpenNewPage
                />
                <div id="contact" className="inner-page contact">
                        <ContactForm brand='bagni' />
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default Bagni